var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"发送就餐提醒"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('span',{staticStyle:{"margin-left":"30px"}},[_vm._v("就餐提醒信息")]),_c('a-form',{staticClass:"custom-compact-form",staticStyle:{"margin-top":"15px"},attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"提醒内容"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['content', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入提醒内容' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['content', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入提醒内容' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"就餐地点"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['location', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入就餐地点' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['location', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入就餐地点' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"开始时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['start_at', {
            rules: [
              { required: true, message: "请选择开始时间" }
            ]
          }]),expression:"['start_at', {\n            rules: [\n              { required: true, message: `请选择开始时间` }\n            ]\n          }]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD HH:mm","show-time":{ format: 'HH:mm' }},on:{"openChange":_vm.handleStartOpenChange}})],1),_c('a-form-item',{attrs:{"label":"结束时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['end_at', {
            rules: [
              { required: true, message: "请选择结束时间" }
            ]
          }]),expression:"['end_at', {\n            rules: [\n              { required: true, message: `请选择结束时间` }\n            ]\n          }]"}],staticStyle:{"width":"100%"},attrs:{"format":"YYYY-MM-DD HH:mm","show-time":{ format: 'HH:mm' }},on:{"openChange":_vm.handleEndOpenChange}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }